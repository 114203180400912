<template>
  <div ref="quiz">
    <v-card class="shadow rounded-lg mb-6" :loading="questions[0].upload_loading">
      <v-card-text class="pa-6">
        <v-row justify="center">
          <v-col cols="12" lg="12">

            <v-text-field label="Description *"
                          filled dense
                          class="mb-2"
                          hide-details
                          v-model.trim="questions[0].description"></v-text-field>

            <v-text-field label="* الوصف"
                          class="ar rtl"
                          filled dense
                          hide-details
                          v-model.trim="questions[0].description_ar"></v-text-field>

            <v-switch inset dense v-model="questions[0].is_video"
                      label="Vidéo ?"/>

            <div @click="onButtonClick" v-if="!questions[0].is_video" class="text-center pa-6 cursor-pointer"
                 style="border-style: dashed;">
              <v-btn :loading="questions[0].upload_loading"
                     text class="v-btn--active pa-10" color="primary" fab depressed>
                <v-icon color="primary">mdi-cloud-upload</v-icon>
              </v-btn>
              <strong class="mt-4 d-block">Télécharger une fichier (PDF,Image)</strong>
              <input ref="uploader" class="d-none" type="file"
                     @change="uploadFile($event)">
            </div>

          </v-col>
        </v-row>

        <div v-if="!questions[0].is_video">
          <div class="d-flex justify-space-between mt-1 pa-4 rounded"
               :class="$vuetify.theme.dark ? 'grey' : 'grey lighten-3'"
               v-if="questions[0].content">
            <v-chip color="primary" small @click="goTo(questions[0].content)">
              {{ FILE_URL + questions[0].content }}
            </v-chip>
            <v-spacer/>
            <v-icon color="blue">mdi-check-circle</v-icon>
          </div>
          <v-alert type="error" text v-if="errors">
            <ul>
              <li v-for="(error,i) in errors" :key="i">{{ error[0] }}</li>
            </ul>
          </v-alert>
        </div>

        <div v-if="questions[0].is_video">
          <v-text-field v-model="questions[0].content"
                        append-icon="mdi-youtube"
                        label="Entrez l'url de la vidéo"></v-text-field>
          <v-btn depressed class="rounded-lg" @click="show_video = !show_video">
            <v-icon left color="red">mdi-youtube</v-icon>
            {{ show_video ? 'Masquer' : 'Afficher' }} la vidéo
          </v-btn>
        </div>

        <div v-if="show_video" class="video-container mt-4">
          <iframe :src="'//www.youtube.com/embed/'+questions[0].content+'?autoplay=1&mute=1&loop=1'"
                  frameborder="0" allowfullscreen class="video"
                  allow="autoplay; encrypted-media">
          </iframe>
        </div>

      </v-card-text>
    </v-card>

    <v-card class="shadow rounded-lg mb-6"
            v-for="(el,i) in questions.slice(1,questions.length)"
            :key="i">
      <v-btn color="blue" depressed right fab dark small absolute top>
        {{ i + 1 }}
      </v-btn>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" class="text-center">
            <v-text-field v-model="el.description" label="Question *"></v-text-field>
            <v-text-field v-model="el.description_ar"
                          label="* السؤال"
                          class="ar rtl"></v-text-field>
            <p>
              Selectionnez un type *
            </p>
            <v-btn @click="[el.question_type_key='radio',el.answer_type_id =3]"
                   outlined rounded
                   text large
                   :color="el.question_type_key === 'radio' ? 'primary' :''"
                   class="text-none mr-2">
              <v-icon left v-if="el.question_type_key === 'radio'">mdi-check</v-icon>
              Choix unique
              <v-icon right>mdi-radiobox-marked</v-icon>
            </v-btn>
            <v-btn
                @click="[el.question_type_key ='checkbox',el.answer_type_id =4]"
                outlined rounded
                text large
                :color="el.question_type_key === 'checkbox' ? 'primary' :''"
                class="text-none mr-2">
              <v-icon left v-if="el.question_type_key === 'checkbox'">mdi-check</v-icon>
              Cases à cocher
              <v-icon right>mdi-checkbox-marked</v-icon>
            </v-btn>
            <v-card outlined class="mt-4">
              <v-card-text>
                <div v-for="(item,index) in el.options" :key="index" class="mb-3">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                          :prepend-inner-icon="el.question_type_key === 'checkbox' ? 'mdi-checkbox-marked' :'mdi-radiobox-marked'"
                          v-model="item.option"
                          outlined dense hide-details
                          :label="el.question_type_key === 'checkbox' ? 'Choix ' +(index+1): 'Option '+(index+1)"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                          :prepend-inner-icon="el.question_type_key === 'checkbox' ? 'mdi-checkbox-marked' :'mdi-radiobox-marked'"
                          :append-icon="el.options.length > 1 ? 'mdi-delete-outline' : ''"
                          v-model="item.option_ar"
                          @click:append="removeOption(index,i)"
                          outlined dense hide-details
                          class="ar"
                          :label="el.question_type_key === 'checkbox' ? 'الخيار ' +(index+1): 'الخيار '+(index+1)"></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <v-btn fab small depressed @click="addOption(i)">
                  <v-icon color="blue">mdi-plus</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-tooltip v-if="questions.length > 1"
                   bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                @click="removeQuestion(i)"
                icon v-bind="attrs" v-on="on">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>Supprimer</span>
        </v-tooltip>
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                @click="duplicateQuestion(el)"
                icon v-bind="attrs" v-on="on">
              <v-icon>mdi-content-duplicate</v-icon>
            </v-btn>
          </template>
          <span>Dupliquer</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
    <div class="text-center mt-3">
      <v-btn color="blue" dark fab small depressed @click="addQuestion">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['taskIndex', 'questions'],
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
      errors: '',
      show_video: false,
    }
  },
  methods: {
    uploadFile(file) {
      this.questions[0].upload_loading = true
      this.errors = ''
      let formData = new FormData()
      formData.append('file', file.target.files[0])
      HTTP.post('/campaigns/upload-file', formData).then((res) => {
        this.questions[0].upload_loading = false
        this.questions[0].content = res.data.file_name
      }).catch(err => {
        this.questions[0].upload_loading = false
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."*
        console.log(err)
      })
    },
    addQuestion() {
      let data = {
        question_type_key: 'radio',
        question_type_id: 4,
        answer_type_id: 3,
        content: '',
        upload_loading: false,
        is_video: false,
        is_none: false,
        description: '',
        description_ar: '',
        options: [
          {
            option: '',
            option_ar: '',
          }
        ],
      }
      this.questions.push(data)
    },
    addOption(i) {
      let data = {
        option: '',
        option_ar: '',
      }
      let questionIndex = i + 1
      this.questions[questionIndex].options.push(data)
    },
    removeOption(index, i) {
      let data = {
        questionIndex: i + 1,
        optionIndex: index,
      }
      this.questions[data.questionIndex].options.splice(data.optionIndex, 1)
    },
    removeQuestion(i) {
      let questionIndex = i + 1
      this.questions.splice(questionIndex, 1)
    },
    duplicateQuestion(el) {
      this.questions.push(JSON.parse(JSON.stringify(el)))
    },
    onButtonClick() {
      window.addEventListener('focus', () => {
      }, {once: true})
      this.$refs.uploader.click()
    },
    goTo(link) {
      window.open(this.FILE_URL + link, '_blanc')
    }
  },
}
</script>

<style scoped>
.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>