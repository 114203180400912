<template>
  <div>
    <v-container fluid>
      <v-row align="center" justify="center">

        <v-col cols="12" lg="8">
          <v-toolbar class="rounded-lg transparent" flat>
            <v-tabs v-model="active_tab">
              <v-tab class="b-700" disabled>
                <v-skeleton-loader v-if="!campaign" type="button"/>
                <span v-else>
                <span class="text--primary">Campagne : </span>   {{ campaign.name }}
                </span>
              </v-tab>
              <v-tab :disabled="!campaign" class="b-700">
                <v-icon left>
                  {{ $route.name === 'task.duplicate' ? 'mdi-content-duplicate' : 'mdi-pencil-outline' }}
                </v-icon>
                {{ $route.name === 'task.duplicate' ? 'Dupliquer' : 'Modifier' }} une tâche
              </v-tab>
            </v-tabs>
            <v-spacer/>
            <v-btn class="mt-3" color="primary"
                   depressed
                   rounded
                   @click="$router.push('/campaigns')">
              <v-icon left>mdi-arrow-left</v-icon>
              Retour
            </v-btn>
          </v-toolbar>
        </v-col>

        <v-col cols="12" lg="8">
          <v-alert :value="isDisabled" prominent text type="info">
            Vous ne pouvez pas modifier cette tâche car sa date de début est passée
          </v-alert>
          <v-alert :value="isError" dismissible prominent text type="error">
            <ul>
              <li v-for="(error,i) in errors" :key="i">{{ error[0] }}</li>
            </ul>
          </v-alert>
          <v-card :disabled="isDisabled || loading" :loading="loading" class="rounded-lg shadow">
            <v-card-text class="pa-10">
              <div v-if="$route.name === 'task.duplicate'"
                   :class="$vuetify.theme.dark ? 'grey' : 'grey lighten-3'"
                   class="pa-4 mb-4">
                <v-autocomplete v-model="form.campaign_id" :items="campaigns"
                                hide-details
                                item-text="name"
                                item-value="id"
                                label="La campagne qui veut dupliquer *"
                                outlined
                                prepend-inner-icon="mdi-note-text"
                                @change="setCampaign"/>
              </div>

              <v-row>
                <v-col>
                  <v-text-field v-model.trim="form.name" label="Nom *"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model.trim="form.name_ar"
                                class="ar rtl"
                                label="الإ سم *"></v-text-field>
                </v-col>
              </v-row>

              <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-click="false"
                      :close-on-content-click="false"
                      min-width="auto"
                      offset-y
                      transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :value="form.dates.join(' ~ ')"
                                append-icon="mdi-calendar" label="Planification *" readonly
                                v-bind="attrs" v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker v-model="form.dates"
                               :min="date_now" range scrollable>
                  <v-spacer></v-spacer>
                  <v-btn class="rounded-lg" text
                         @click="menu = false">
                    <v-icon left>mdi-close-circle-outline</v-icon>
                    Fermer
                  </v-btn>
                  <v-btn :disabled="form.dates.length < 2" class="rounded-lg"
                         color="primary"
                         text
                         @click="menu = false">
                    <v-icon left>mdi-check-circle-outline</v-icon>
                    Ok
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-text-field v-model.trim="form.winning_points"
                            label="Points gagnants *"
                            suffix="Pts">
              </v-text-field>

              <v-file-input
                      :clearable="false"
                      :disabled="upload_loading"
                      :error-messages="upload_errors.photo"
                      :loading="upload_loading"
                      accept="image/png,image/jpeg"
                      append-icon="mdi-image-outline"
                      chips label="Photo" prepend-icon=""
                      show-size
                      small-chips
                      @change="uploadPhoto($event)"
                      @click="upload_errors && upload_errors.photo ? upload_errors.photo = '' : false"></v-file-input>

              <div v-if="form.photo"
                   :class="$vuetify.theme.dark ? 'grey' : 'grey lighten-3'"
                   class="d-flex justify-space-between pa-3 rounded"
                   style="margin-top:-10px">
                <v-avatar size="40"
                          @click="[image = [{src: FILE_URL + form.photo}],visible = true]">
                  <v-img :src="FILE_URL + form.photo" class="rounded cursor-pointer"/>
                </v-avatar>
                <v-spacer/>
                <v-icon color="success">mdi-check-circle</v-icon>
              </div>

              <v-textarea v-model.trim="form.description"
                          hide-details
                          label="Description"
                          rows="2"></v-textarea>

              <v-textarea v-model.trim="form.description_ar"
                          class="ar rtl"
                          hide-details
                          label="* الوصف"
                          rows="2"></v-textarea>

              <div class="d-flex justify-space-between mt-5">
                <v-btn class="mr-1 rounded-lg white--text"
                       color="blue"
                       depressed @click="openQuestionsDialog">
                  <v-icon left>mdi-file-question-outline</v-icon>
                  Tâche affectée
                </v-btn>
                <v-spacer/>
                <v-chip class="text-uppercase" color="blue" dark
                        @click="openSalepointDialog()">
                  <v-icon left>mdi-map-marker-outline</v-icon>
                  {{ form.salepoint_ids.length }} - POS Affectées
                </v-chip>
              </div>

            </v-card-text>
          </v-card>
          <div class="d-flex justify-space-between mt-3">
            <v-spacer/>
            <v-btn v-if="!isDisabled"
                   :disabled="isLoading"
                   :loading="isLoading"
                   class="rounded-lg"
                   color="primary"
                   depressed
                   @click="validate()">
              <v-icon left>mdi-content-save-outline</v-icon>
              Enregistrer
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <!--Dialogs-->

      <vue-easy-lightbox :imgs="image"
                         :index="index"
                         :visible="visible"
                         escDisabled
                         moveDisabled
                         @hide="visible = false"/>

      <SalepointsDialog v-if="!loading"
                        ref="salepointsDialog"
                        :dialog.sync="salepointsDialog"
                        :task="form"/>

      <QuestionsDialog v-if="!loading"
                       ref="questionsDialog"
                       :dialog.sync="questionsDialog"
                       :questions.sync="form.questions"
                       :task_model.sync="form.task_model"
                       @set-questions="form.questions = $event"/>

      <v-dialog
              v-model="validateDialog"
              max-width="400" persistent>
        <v-card class="text-center">
          <v-card-text class="pa-6">
            <v-icon color="primary" size="80">mdi-information-outline</v-icon>
            <h2 class="text--primary mt-3">CONFIRMATION</h2>
            <p class="mt-5 mb-5">Voulez-vous vraiment ENRIGISTRER cette tâche ?</p>
            <v-btn class="rounded-lg mr-2" text
                   @click="validateDialog = false">
              <v-icon left>mdi-close</v-icon>
              Annuler
            </v-btn>
            <v-btn :loading="btnLoading" class="rounded-lg"
                   color="primary"
                   depressed @click="save">
              <v-icon left>mdi-content-save-outline</v-icon>
              Enrigistrer
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!--Dialogs-->

    </v-container>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";
import SalepointsDialog from "./Dialogs/SalepointsDialog";
import QuestionsDialog from "./Dialogs/QuestionsDialog";

export default {
    components: {QuestionsDialog, SalepointsDialog},
    data() {
        return {
            campaign: '',
            active_tab: '',
            loading: false,
            btnLoading: false,


            FILE_URL: process.env.VUE_APP_FILE_URL,
            date_now: this.$moment().format('YYYY-MM-DD'),


            //Upload photo

            upload_loading: false,
            upload_errors: [],

            menu: false,
            form: {
                task_model: '',
                task_model_id: '',
                question_type_key: '',
                campaign_id: '',
                name: '',
                name_ar: '',
                description: '',
                description_ar: '',
                dates: [],
                photo: '',
                winning_points: '',
                salepoint_ids: [],
                questions: [{
                    conditions: [],
                    options: [],
                    is_video: false,
                    is_none: false,
                }],
            },

            salepointsDialog: false,
            questionsDialog: false,

            //Show image
            image: '',
            visible: false,
            index: 0,

            //validations
            isError: false,
            isLoading: false,
            validateDialog: false,
            errors: '',

            //cant update
            isDisabled: false
        }
    },
    methods: {
        getTaskById() {
            this.loading = true
            this.$Progress.start()
            HTTP.get('/campaigns/task/' + parseInt(this.$route.params.id) + '/show').then((res) => {
                if (res.data.data) {
                    this.loading = false
                    this.$Progress.finish()
                    let task = res.data.data
                    this.campaign = task.campaign

                    this.form.task_model = task.task_model
                    this.form.task_model_id = task.task_model_id
                    this.form.campaign_id = parseInt(this.campaign.id)
                    this.form.name = task.name
                    this.form.name_ar = task.name_ar
                    this.form.description = task.description
                    this.form.description_ar = task.description_ar
                    this.form.dates = this.$route.name === 'task.duplicate' ? [] : [task.start_date, task.end_date]
                    this.form.photo = task.photo
                    this.form.winning_points = task.winning_points
                    this.form.salepoint_ids = task.salepoints.map(el => el.id)
                    this.form.questions = task.questions.map(element => {
                        element['is_video'] = element.question_type.key === 'video';
                        element['is_none'] = element.question_type.key === 'none';
                        return element
                    })
                    if (task.task_model && task.task_model.question_type.key === 'condition') {
                        this.form.question_type_key = 'condition'
                        this.form.questions[0].conditions = task.questions[0].conditions.map(el => {
                            return {
                                id: el.product_id,
                                photo: el.product.photo,
                                code: el.product.code,
                                name: el.product.name,
                                is_box: el.is_box !== 0,
                                quantity: el.quantity,
                            }
                        })
                    }
                    if (!task.task_model) {
                        this.form.questions = task.questions.map(el => {
                            return {
                                question_type_key: el.answer_type.key,
                                question_type_id: el.question_type_id,
                                answer_type_id: el.answer_type_id,
                                content: el.content,
                                upload_loading: false,
                                is_video: el.question_type.key === 'video',
                                is_none: el.answer_type.key === 'none',
                                description: el.description,
                                description_ar: el.description_ar,
                                options: el.options.length ? el.options.map(item => {
                                    return {
                                        option: item.option,
                                        option_ar: item.option_ar,
                                    }
                                }) : [],
                            }
                        })
                    }
                    this.active_tab = 1

                    if (this.$route.name === 'task.update') {
                        let date = this.$moment(task.start_date)
                        let now = this.$moment();
                        if (now >= date) {
                            this.isDisabled = true
                        }
                    }

                } else {
                    this.$router.push('/404')
                }
            }).catch(err => {
                this.$Progress.fail()
                this.loading = false
                console.log(err)
            })
        },
        uploadPhoto(file) {
            this.upload_loading = true
            this.upload_errors = []
            let formData = new FormData()
            formData.append('photo', file)
            HTTP.post('/campaigns/upload-image', formData).then((res) => {
                this.form.photo = res.data.file_name
                this.upload_loading = false
            }).catch(err => {
                this.upload_loading = false
                this.upload_errors = err.response.data.errors
                this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
                console.log(err)
            })
        },
        openSalepointDialog() {
            this.salepointsDialog = true
            this.$refs.salepointsDialog.setSalepointIds(this.form.salepoint_ids)
        },
        openQuestionsDialog() {
            this.questionsDialog = true
        },
        setCampaign() {
            let campaigns = this.campaigns
            this.campaign = campaigns.find(el => el.id === this.form.campaign_id)
        },
        validate() {
            this.isLoading = true
            this.isError = false
            this.$Progress.start()
            HTTP.post('/campaigns/validate/update-or-duplicate-task', {
                tasks: [this.form]
            }).then(() => {
                this.isLoading = false
                this.isError = false
                this.$Progress.finish()
                this.validateDialog = true
            }).catch(err => {
                this.isLoading = false
                this.isError = true
                this.$Progress.fail()
                this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
                this.errors = err.response.data.errors
                this.$vuetify.goTo(0)
                console.log(err)
            })
        },
        save() {
            this.btnLoading = true
            this.loading = true

            HTTP.post('/campaigns/task/update-or-duplicate', {
                task: this.form,
                campaign_id: this.form.campaign_id,
                task_id: parseInt(this.$route.params.id),
                type: this.$route.name === 'task.update' ? 'update' : 'duplicate',
            }).then(() => {
                this.btnLoading = false
                this.loading = false
                this.$successMessage = 'Cette tâche a été enregistrée avec succès !'
                this.$router.push('/campaigns')
            }).catch(err => {
                this.btnLoading = false
                this.loading = false
                if (err.response.data.error) {
                    this.isDisabled = true
                    this.$errorMessage = err.response.data.error
                    this.validateDialog = false
                    this.$vuetify.goTo(0)
                }
                console.log(err)
            })
        }
    },
    created() {
        this.getTaskById()
    },
    computed: {
        campaigns() {
            return this.$store.state.campaigns
        }
    }
}
</script>

<style scoped>

</style>