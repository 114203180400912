<template>
  <div>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition">
      <v-card class="fullscreen">
        <v-card-title>
          Tâche affectée
          <v-spacer/>

          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs"
                      v-on="on" @click="close">mdi-close
              </v-icon>
            </template>
            <span>Fermer</span>
          </v-tooltip>

        </v-card-title>
        <v-divider/>
        <v-container fluid>
          <v-row justify="center">

            <v-col cols="12" lg="3">
              <v-card class="shadow rounded-lg sticky-top-0">
                <v-card-title>
                  Tâche sélectionné
                </v-card-title>
                <v-divider/>
                <v-card-text>

                  <v-list nav>
                    <v-list-item-group color="primary">

                      <v-list-item
                              v-for="(item, i) in taskModels" :key="i"
                          :class="task_model && task_model.question_type.key === item.question_type.key ? 'v-list-item--active':''"
                          :disabled="(task_model && task_model.question_type.key !== item.question_type.key) || !task_model">
                        <v-list-item-icon>
                          <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-html="item.name"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action v-if="task_model && task_model.question_type.key === item.question_type.key">
                          <v-icon>mdi-check</v-icon>
                        </v-list-item-action>
                      </v-list-item>

                      <v-list-item
                          :class="!task_model ? 'v-list-item--active':''"
                          :disabled="!!task_model">
                        <v-list-item-icon>
                          <v-icon>mdi-clipboard-file-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Quiz / Formation</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action v-if="!task_model">
                          <v-icon>mdi-check</v-icon>
                        </v-list-item-action>
                      </v-list-item>

                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" lg="7">
              <div v-if="task_model && task_model.question_type.key === 'image'">
                <Photo :questions.sync="questions"/>
              </div>
              <div v-if="task_model && task_model.question_type.key === 'condition'">
                <Conditions :questions.sync="questions"/>
              </div>
              <div v-if="!task_model">
                <Quiz :questions.sync="questions"/>
              </div>
              <div class="d-flex justify-space-between mt-3">
                <v-spacer/>
                <v-btn color="primary"
                       @click="close"
                       depressed
                       class="rounded-lg">
                  Valider
                  <v-icon right>mdi-check</v-icon>
                </v-btn>
              </div>
            </v-col>

          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Photo from "../fields/Photo";
import Conditions from "../fields/Conditions";
import Quiz from "../fields/Quiz";

export default {
  components: {Quiz, Conditions, Photo},
  props: ['dialog', 'task_model', 'questions'],
  methods: {
    close() {
      this.$emit('update:dialog', false)
    }
  },
  computed: {
    taskModels() {
      return this.$store.state.task_models;
    },
  }
}
</script>

<style scoped>
</style>