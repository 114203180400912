<template>
  <div>
    <v-card class="shadow rounded-lg">
      <v-card-text>
        <v-row justify="center">
          <v-col class="text-right">
            <v-btn @click="openProductsDialog" depressed dark
                   color="blue"
                   class="rounded-lg">
              <v-icon left>mdi-plus</v-icon>
              Selectionnez les Produits
            </v-btn>
          </v-col>

          <v-col cols="12" class="text-center">

            <v-text-field label="Description *"
                          filled dense
                          hide-details
                          class="mb-2"
                          v-model.trim="questions[0].description"></v-text-field>

            <v-text-field label="* الوصف"
                          class="ar rtl mb-2"
                          filled dense
                          hide-details
                          v-model.trim="questions[0].description_ar"></v-text-field>

            <v-alert type="info" text>
              <strong>Remarque : </strong> {{ response }}
            </v-alert>

            <div class="text-center" v-if="questions[0].conditions.length === 0">
              <v-avatar tile size="250">
                <v-img :src="require('@/assets/list.svg')"/>
              </v-avatar>
            </div>

            <v-simple-table v-else class="table-border">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Image
                  </th>
                  <th class="text-left">
                    Code
                  </th>
                  <th class="text-left">
                    Produit
                  </th>
                  <th class="text-left">
                    Box
                  </th>
                  <th class="text-left">
                    Qunatité
                  </th>
                  <th class="text-right">
                    Action
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(condition,i) in questions[0].conditions" :key="i">
                  <td class="text-left">
                    <v-img v-if="condition.photo" :src="FILE_URL + condition.photo"
                           class="rounded-circle"
                           width="40" height="40"/>
                    <v-img v-else :src="require('@/assets/no-image.png')"
                           class="rounded-circle"
                           width="40" height="40"/>
                  </td>
                  <td class="text-left">{{ condition.code }}</td>
                  <td class="text-left">{{ condition.name }}</td>
                  <td class="text-left">
                    <v-switch dense v-model="condition.is_box"></v-switch>
                  </td>
                  <td class="text-left">
                    <div class="d-flex">
                      <v-btn fab x-small depressed
                             @click="decrementCondition(i)">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <span class="mx-2 mt-1">
                       {{ condition.quantity }}
                     </span>
                      <v-btn fab x-small color="primary" depressed
                             @click="incrementCondition(i)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </td>
                  <td class="text-right">
                    <v-btn icon @click="removeCondition(i)">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

          </v-col>

        </v-row>
      </v-card-text>
    </v-card>

    <!--Dialogs-->

    <ProductsDialog ref="productsDialog"
                    :questions.sync="questions"
                    :dialog.sync="productsDialog"/>
  </div>
</template>

<script>


import ProductsDialog from "../Dialogs/ProductsDialog";

export default {
  components: {ProductsDialog},
  props: ['questions'],
  data() {
    return {
      productsDialog: false,
      FILE_URL: process.env.VUE_APP_FILE_URL,
      response: 'La réponse de cette tâche est une image',
    }
  },
  methods: {
    openProductsDialog() {
      this.productsDialog = true
      this.$refs.productsDialog.setSelectedItems()
    },
    decrementCondition(i) {
      if (this.questions[0].conditions[i].quantity > 1)
        this.questions[0].conditions[i].quantity -= 1
    },
    incrementCondition(i) {
      this.questions[0].conditions[i].quantity += 1
    },
    removeCondition(i) {
      this.questions[0].conditions.splice(i, 1)
    }
  }
}
</script>

<style scoped>

</style>