<template>
  <div>
    <v-dialog v-model="dialog"
              max-width="1000"
              persistent
              scrollable>
      <v-card>
        <v-card-title>
          Affectation des POS
          <v-spacer/>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs"
                      @click="close" v-on="on">mdi-close
              </v-icon>
            </template>
            <span>Fermer</span>
          </v-tooltip>
        </v-card-title>
        <v-divider/>

        <v-card-text>

          <v-tabs v-model="tab" centered class="mt-5" hide-slider>

            <v-tab active-class="primary white--text" class="font-weight-bold" href="#tab-1">
              Filtrage simple
              <v-icon right>mdi-filter-outline</v-icon>
            </v-tab>

            <v-tab active-class="primary white--text" class="font-weight-bold" href="#tab-2">
              Filtrage par un fichier excel
              <v-icon right>mdi-file-excel-outline</v-icon>
            </v-tab>

          </v-tabs>

          <v-tabs-items v-model="tab">

            <v-tab-item :value="'tab-1'">
              <v-card flat>
                <v-card-text>
                  <SPFilterComponent ref="SPFilterComponent"
                                     @set-salepoint-ids="[filter_salepoint_ids = $event,setSalepointIds()]"/>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-2'">
              <v-card flat>
                <v-card-text>
                  <SPExcelFilterComponent ref="SPExcelFilterComponent"
                                          @set-salepoint-ids="[filter_salepoint_ids = $event,setSalepointIds()]"/>
                </v-card-text>
              </v-card>
            </v-tab-item>

          </v-tabs-items>

          <SPListComponent ref="affectedSalepoints" :task="task"/>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SPFilterComponent from '../../../components/SPFilterComponent'
import SPExcelFilterComponent from '../../../components/SPExcelFilterComponent'
import SPListComponent from "../../../components/SPListComponent";

export default {
   props: ['dialog', 'task'],
   components: {
      SPListComponent,
      SPFilterComponent,
      SPExcelFilterComponent
   },
   data() {
      return {
         tab: null,
         filter_salepoint_ids: []
      }
   },
   methods: {
      close() {
         this.$emit('update:dialog', false)
      },
      setSalepointIds(salepointIds) {
         let _vm = this
         setTimeout(() => {
            _vm.$refs.affectedSalepoints.getAffectedSalepoints(salepointIds,1,this.filter_salepoint_ids)
         }, 0);
      }
   },
}
</script>

<style scoped>

</style>